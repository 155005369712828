import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import { message } from "antd";

import {
  setUser,
  fetchUserProfile,
  setSigningOutProcess,
} from "../features/user/UserSlice";
import { loadRecord } from "../features/organization/organizationSlice";
import { getCurrentTenant } from "../api/server";
import * as Sentry from "@sentry/browser";

import store from "../redux/store";

export const configurations = {
  production: {
    apiKey: "AIzaSyC4XYWtPgsMSIHkzJrRm4lz4Osae97BY2Y",
    authDomain: "steam-capsule-316104.firebaseapp.com",
    projectId: "steam-capsule-316104",
    storageBucket: "steam-capsule-316104.appspot.com",
    messagingSenderId: "793066330760",
    appId: "1:793066330760:web:c38f3c358a13eb50de1d3d",
    masterTenant: "master-ujuwd",
    masterDomain: "master.a-dreams.com",
  },
  matav: {
    apiKey: "AIzaSyC4XYWtPgsMSIHkzJrRm4lz4Osae97BY2Y",
    authDomain: "coherent-medium-332412.firebaseapp.com",
    projectId: "coherent-medium-332412",
    storageBucket: "coherent-medium-332412.appspot.com",
    messagingSenderId: "65563686053",
    appId: "1:65563686053:web:118095370539a6dbd5494c",
    masterTenant: "master-zeevx",
    masterDomain: "dreams-master.matav.org.il",
  },
  dev: {
    apiKey: process.env.REACT_APP_API_KEY || "AIzaSyB9qwava8-KbE--nFcoMVDcsgvJAqgmxLc",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN || "dreams-da917.firebaseapp.com",
    databaseURL: process.env.REACT_APP_DATABASE_URL || "https://dreams-da917.firebaseio.com",
    projectId: process.env.REACT_APP_PROJECT_ID || "dreams-da917",
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET || "dreams-da917.appspot.com",
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "320497435948",
    appId: process.env.REACT_APP_APP_ID || "1:320497435948:web:229b0bae39762d95bdd889",
    masterTenant: process.env.REACT_APP_MASTER_TENANT || "test-master-mw5sz",
    masterDomain: process.env.REACT_APP_MASTER_DOMAIN || "localhost:3000",
  },
};
export const firebaseConfig =
  configurations[process.env.REACT_APP_CONFIG || "dev"];

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().languageCode = "he";

let didSetSentryUser = false;
axios.interceptors.request.use((request) => {
  const currentUser = firebaseApp.auth().currentUser;
  const expiration = localStorage.getItem("dreams-expiration");
  if (!currentUser) {
    return request;
  }
  const limit = 2 * 60 * 60 * 1000; //2 hours
  if (+expiration < Date.now()) {
    store.dispatch(setSigningOutProcess(true));
    return firebaseApp
      .auth()
      .signOut()
      .then(() => {
        store.dispatch(setSigningOutProcess(false));
        return request;
      });
  } else if (expiration !== null) {
    if (!didSetSentryUser) {
      Sentry.setUser({ email: currentUser.email });
      didSetSentryUser = true;
    }
    return currentUser
      .getIdToken()
      .then((token) => {
        localStorage.setItem("dreams-expiration", Date.now() + limit);
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        };
        return request;
      })
      .catch(() => request);
  }
});

/**
 * Interceptor (middleware) which checks local storage for items to log.
 * If items are found, sets a custom request header to tell the backend's
 * middleware to log to a table.
 */
axios.interceptors.request.use((request) => {
  const logItems = localStorage.getItem("logItems");
  if (logItems !== null) {
    try {
      let parsedLogData;
      parsedLogData = JSON.parse(logItems);
      console.log("requesting backend to log user events");
      request.headers["X-Dreams-Log-User-Data"] = JSON.stringify(parsedLogData);
    } catch (e) {
      console.warn("error logging user logs");
    } finally {
      localStorage.removeItem("logItems");
      return request;
    }
  }
  return request;
});

class Auth {
  select(state) {
    return state.registration.registrationInProgress;
  }
  constructor() {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      const regInProgress = store.getState().registration
        .registrationInProgress;
      if (regInProgress) {
        return;
      }

      if (user) {
        const limit = 2 * 60 * 60 * 1000; //2 hours
        localStorage.setItem("dreams-expiration", Date.now() + limit);
        if (process.env.REACT_APP_BUILD_TARGET !== "tenants") {
          const action = await store.dispatch(fetchUserProfile());
          if (!action.error) {
            store.dispatch(loadRecord());
          } else {
            this.signOut();
            store.dispatch(setUser(null));
            message.error("המשתמש אינו קיים");
          }
        } else {
          const profile = {
            displayName: user.displayName,
            email: user.email,
          };
          store.dispatch(setUser(profile));
        }
      } else {
        localStorage.setItem("dreams-expiration", null);
        store.dispatch(setUser(null));
      }
    });
  }

  async signInWithProvider(provider, providerTenant) {
    var azureProvider = new firebase.auth.OAuthProvider(provider);
    azureProvider.setCustomParameters({
      tenant: providerTenant,
    });
    let tenant = await getCurrentTenant();
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    return firebase.auth().signInWithPopup(azureProvider);
  }

  async getToken() {
    const currentUser = firebaseApp.auth().currentUser;
    if (!currentUser) {
      return null;
    }
    return await currentUser.getIdToken();
  }

  async signInWithEmailAndPassword(
    email,
    password,
    isSystemUser,
    ref,
    recaptcha
  ) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants" || isSystemUser) {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    var recaptchaVerifier = recaptcha
      ? recaptcha
      : new firebase.auth.RecaptchaVerifier(ref, {
        size: "invisible",
      });

    var resolver;

    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const userInfo = await auth.signInWithEmailAndPassword(email, password);
      if (userInfo) {
        return {
          is2FA: false,
          userInfo,
          resolver: null,
          verificationId: null,
          error: null,
        };
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        resolver = error.resolver;
        var phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        return {
          is2FA: true,
          userInfo: null,
          resolver,
          verificationId,
          recaptchaVerifier,
          error: null,
        };
      } else {
        return {
          is2FA: false,
          userInfo: null,
          resolver: null,
          verificationId: null,
          error,
        };
        // Handle other errors such as wrong password.
      }
    } finally {
    }
  }

  async resend2FACode(resolver, recaptchaVerifier) {
    var phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
    return {
      verificationId,
      error: null,
    };
  }

  async confirm2FACode(resolver, verificationId, verificationCode) {
    var cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
      cred
    );
    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion);
  }

  async sendPasswordResetEmail(email) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    firebaseApp.auth().tenantId = tenant.name;
    return firebaseApp.auth().sendPasswordResetEmail(email);
  }

  changePassword(tenantId, actionCode, password) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        auth.confirmPasswordReset(actionCode, password);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  async linkProvider(
    tenantId,
    authProvider,
    providerTenant,
    email,
    href,
    history
  ) {
    const auth = firebaseApp.auth();
    var provider = new firebase.auth.OAuthProvider(authProvider);
    provider.setCustomParameters({
      tenant: providerTenant,
    });
    auth.tenantId = tenantId;
    await auth.signInWithEmailLink(email, href);
    const result = await auth.currentUser.linkWithPopup(provider);
    var credential = result.credential;
    await auth.signInWithCredential(credential);
  }

  linkAccount(email, password, history) {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebaseApp
      .auth()
      .currentUser.linkWithCredential(credential)
      .then(function(usercred) {
        var user = usercred.user;
        console.log("Account linking success", user);
        const url = "/";
        history.push(url);
      })
      .catch(function(error) {
        console.log("Account linking error", error);
      });
  }

  async signOut() {
    store.dispatch(setSigningOutProcess(true));
    try {
      await firebaseApp.auth().signOut();
    } catch (error) {
      console.log("Sign out error", error);
    } finally {
      store.dispatch(setSigningOutProcess(false));
    }
  }

  async signInWithLink(tenantId, email) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth.signInWithEmailLink(email);
  }
}

export default new Auth();
