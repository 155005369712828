import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  generateProspectus,
  approveAllProspectusLines,
  rejectAllProspectusLines,
  getProspectus,
  listProspectusLines,
  updateProspectusLine,
  deleteProspectusLine,
  lockProspectus,
  createProspectusFromLine,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchProspectus = createAsyncThunk(
  "prospectus/loadProspectus",
  async (params, thunkAPI) => {
    const { error, data } = await getProspectus(params);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load prospectus", message: error })
      );
    }
    return data;
  }
);

export const fetchProspectusLines = createAsyncThunk(
  "prospectus/loadProspectusLines",
  async (listParams, thunkAPI) => {
    const { error, data } = await listProspectusLines(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load prospectuses lines", message: error })
      );
    }
    return data;
  }
);

export const commitChanges = createAsyncThunk(
  "prospectus/update",
  withNotifications(
    async (par, thunkAPI) => {
      const { activeProspectus } = thunkAPI.getState().prospectus;
      const params = {
        branchId: activeProspectus.branch_id,
        prospectusId: activeProspectus.id,
        lineId: par.lineId,
        ...par,
      };
      return updateProspectusLine(params);
    },
    "update_prospectus_line",
    "update_prospectus_line_success",
    "update_prospectus_line_error"
  )
);

export const cancelProspectusLine = createAsyncThunk(
  "prospectus/deleteLine",
  withNotifications(
    async (params, thunkAPI) => {
      return deleteProspectusLine(params);
    },
    "delete_prospectus_line",
    "delete_prospectus_line_success",
    "delete_prospectus_line_error"
  )
);

export const createNewProspectusFromLine = createAsyncThunk(
  "prospectus/createProspectusFromLine",
  withNotifications(
    async (params, thunkAPI) => {
      return createProspectusFromLine(params);
    },
    "create_prospectus_from_line",
    "create_prospectus_from_line_success",
    "create_prospectus_from_line_error"
  )
);

export const generateNewProspectus = createAsyncThunk(
  "prospectus/generate",
  withNotifications(
    async (params, thunkAPI) => {
      return generateProspectus(params);
    },
    "generate_prospectus",
    "generate_prospectus_success",
    "generate_prospectus_error"
  )
);

export const approveEntireProspectus = createAsyncThunk(
  "prospectus/aprroveEntire",
  withNotifications(
    async (params, thunkAPI) => {
      return approveAllProspectusLines(params);
    },
    "approve_entire_prospectus",
    "approve_entire_prospectus_success",
    "approve_entire_prospectus_error"
  )
);

export const rejectEntireProspectus = createAsyncThunk(
  "prospectus/rejectEntire",
  withNotifications(
    async (params, thunkAPI) => {
      return rejectAllProspectusLines(params);
    },
    "reject_entire_prospectus",
    "reject_entire_prospectus_success",
    "reject_entire_prospectus_error"
  )
);

export const lockEntireProspectus = createAsyncThunk(
  "prospectus/lock",
  withNotifications(
    async (params, thunkAPI) => {
      return lockProspectus(params);
    },
    "lock_prospectus",
    "lock_prospectus_success",
    "lock_prospectus_error"
  )
);

const initialPageSize = 100;
const initialState = {
  activeProspectus: null,
  textFilter: null,
  prospectuses: [],
  activeProspectusId: null,
  activeProspectusBranch: null,
  prospectuslines: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      // field: "name",
      // order: "descend",
    },
    items: [],
  },
};

const prospectusSlice = createSlice({
  name: "prospectus",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.prospectuslines = page;
    },
    setActiveProspectus(state, action) {
      const { prospectus } = action.payload;
      state.activeProspectus = prospectus;
    },
    setActiveProspectusId(state, action) {
      state.activeProspectusId = action.payload;
    },
    setActiveProspectusBranch(state, action) {
      state.activeProspectusBranch = action.payload;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.textFilter = text;
    },
    resetProspectus(state, action) {
      state.activeProspectus = initialState.activeProspectus;
      state.textFilter = initialState.textFilter;
      state.prospectuses = initialState.prospectuses;
      state.prospectuslines = initialState.prospectuslines;
      state.activeProspectusId = initialState.activeProspectusId;
      state.activeProspectusBranch = initialState.activeProspectusBranch;
    },
  },
  extraReducers: {
    [fetchProspectus.fulfilled]: (state, action) => {
      state.activeProspectus = action.payload;
    },
    [fetchProspectus.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchProspectusLines.fulfilled]: (state, action) => {
      state.prospectuslines = { ...state.prospectuslines, ...action.payload };
    },
    [fetchProspectusLines.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [commitChanges.fulfilled]: (state, action) => {},
    [commitChanges.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [cancelProspectusLine.fulfilled]: (state, action) => {},
    [cancelProspectusLine.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createNewProspectusFromLine.fulfilled]: (state, action) => {},
    [createNewProspectusFromLine.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [generateNewProspectus.fulfilled]: (state, action) => {},
    [generateNewProspectus.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [approveEntireProspectus.fulfilled]: (state, action) => {},
    [approveEntireProspectus.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [rejectEntireProspectus.fulfilled]: (state, action) => {},
    [rejectEntireProspectus.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [lockEntireProspectus.fulfilled]: (state, action) => {},
    [lockEntireProspectus.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setTextFilter,
  setActiveProspectus,
  resetProspectus,
  setActiveProspectusId,
  setActiveProspectusBranch,
} = prospectusSlice.actions;

export default prospectusSlice.reducer;

export const loadProspectus = () => (dispatch, getState) => {
  const branchId = getState().prospectus.activeProspectusBranch;
  const prospectusId = getState().prospectus.activeProspectusId;
  const params = {
    branchId: branchId,
    prospectusId: prospectusId,
  };
  dispatch(fetchProspectus(params));
};

export const loadProspectuseslines = () => (dispatch, getState) => {
  const newState = {
    ...getState().prospectus.prospectuslines,
    filter: {
      freeText: getState().prospectus.textFilter,
    },
  };
  const branchId = getState().prospectus.activeProspectusBranch;
  const prospectusId = getState().prospectus.activeProspectusId;
  const scopePrefix = getState().user.scopePrefix;
  const path =
    scopePrefix === ""
      ? `branches/${branchId}/accounting/prospectuses/${prospectusId}/lines`
      : `accounting/prospectuses/${prospectusId}/lines`;
  dispatch(setPage(newState));
  if (prospectusId) {
    dispatch(fetchProspectusLines({ ...newState, scopePrefix, path }));
  }
};
